import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const theme = {
  colors: {
    primary: "rebeccapurple",
  },
  config,
};

export default extendTheme(theme);
